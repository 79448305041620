import axios from "axios";
import { getPreSignedUrl } from "../../../services/aws";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";

const AWSFileUpload = (props) => {
  const { multiple, onUploaded, defaultFiles, showRemoveIcon } = props;
  const [uploadingFiles, setUploadingFiles] = useState({});

  const handleUpload = {
    name: "file",
    multiple: multiple,
    defaultFileList: defaultFiles,
    showUploadList: {
      showRemoveIcon: (file) => showRemoveIcon || !!file.originFileObj
    },
    beforeUpload: async (file) => {
      const { data } = await getPreSignedUrl(file.name);
      setUploadingFiles(prev => ({
        ...prev,
        [file.uid]: data.presignUrl
      }));
    },
    customRequest: async ({ file, onError, onSuccess, onProgress }) => {
      const fileType = file.type;
      const signedUrl = uploadingFiles[file.uid];

      if (!signedUrl) {
        onError(new Error('No signed URL available'));
        return;
      }

      var options = {
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file
          );
        },
        headers: {
          "Content-Type": fileType,
        },
      };

      try {
        const result = await axios.put(signedUrl, file, options);
        onSuccess(result, file);
        setUploadingFiles(prev => {
          const newState = { ...prev };
          delete newState[file.uid];
          return newState;
        });
      } catch (error) {
        console.error(error);
        onError(error);
      }
    },
    onChange(info) {
      const { file, fileList } = info;
      
      if (file.status === "done") {
        onUploaded instanceof Function && onUploaded({ ...info, fileList });
        message.success(`${file.name} file uploaded successfully`);
      } else if (file.status === "error") {
        message.error(`${file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    }
  };

  return (
    <Upload {...handleUpload}>
      <Button icon={<UploadOutlined />}>
        Click to upload {multiple ? 'files' : 'a file'}
      </Button>
    </Upload>
  );
}

export default AWSFileUpload;