import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { groupBy, sortBy } from "lodash";
import {
  API_URL,
  createAdminAction,
  createShare,
  deleteAdminAction,
  getActionById,
  getActions,
  getAdminActions,
  getCategoryList,
} from "../services";
import { uniqueId } from "lodash";

export const getActionList = createAsyncThunk(
  API_URL.ACTION_LIST,
  async (params) => {
    const response = await getActions();
    return response.data;
  }
);
export const getActionCategoryList = createAsyncThunk(
  API_URL.CATEGORY_LIST,
  async (params) => {
    const response = await getCategoryList();
    return response.data;
  }
);
export const getAdminActionList = createAsyncThunk(
  API_URL.ADMIN_ACTION_LIST,
  async (params) => {
    const response = await getAdminActions(params);
    return response.data;
  }
);
export const deleteAdminActionList = createAsyncThunk(
  API_URL.ADMIN_ACTION_DELETE,
  async (params) => {
    const response = await deleteAdminAction(params);
    return response.data;
  }
);

const buildActions = (actions, key, actionCategories) => {
  const actionsGroup = { ...actionCategories };
  const listAction = actions.filter((x) => x.applyFor.includes(key));

  const actionsGrouped = groupBy(listAction, "actionCategory");
  const result = actionCategories.map((group) => ({
    ...group,
    children: sortBy(actionsGrouped[group.key] || [], "actionDisplayName").map(
      (action) => ({
        key: `action_${group.key}_${action.id}`,
        label: action.actionDisplayName,
        action: action,
      })
    ),
  }));
  return result.filter(c => c.children.length > 0);
};
export const getActionDetail = createAsyncThunk(
  API_URL.ACTION_DETAIL,
  async (params) => {
    const response = await getActionById(params);
    return response.data;
  }
);
export const saveAction = createAsyncThunk(
  API_URL.ADMIN_ACTION_CREATE,
  async (params) => {
    const response = await createAdminAction(params);
    return response.data;
  }
);

const initialState = {
  isReLoad: false,
  actions: [],
  actionCategories: [],
  actionsPipeline: [],
  actionsDataset: [],
  actionsAiAgent: [],
  actionsObj: {
    list: [],
    total: 0,
    payload: {
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  actionDetail: {},
};

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {
    setPayloadGetAdminActionList: (state, { payload }) => ({
      ...state,
      actionsObj: {
        ...state.actionsObj,
        payload: payload,
      },
    }),
    emptyAction: (state) => {
      state.actionDetail = {};
    },
  },
  extraReducers: {
    [getActionList.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        actions: payload,
        actionsPipeline: buildActions(
          payload,
          "PIPELINE",
          state.actionCategories
        ),
        actionsDataset: buildActions(
          payload,
          "DATASET",
          state.actionCategories
        ),
        actionsAiAgent: buildActions(
          payload,
          "AIAGENT",
          state.actionCategories
        ),
      };
    },
    [getAdminActionList.fulfilled]: (state, { payload }) => {
      state.actionsObj = {
        ...state.actionsObj,
        list: payload.lstData,
        total: payload.total,
      };
    },
    [getActionDetail.fulfilled]: (state, { payload }) => {
      state.actionDetail = payload;
    },
    [getActionCategoryList.fulfilled]: (state, { payload }) => {
      state.actionCategories = payload.map((x) => {
        return {
          key: x.name,
          label: x.name,
          children: [],
        };
      });
    },
  },
});

export const { setPayloadGetAdminActionList, emptyAction } = adminSlice.actions;

export default adminSlice.reducer;
