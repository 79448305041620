import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  message,
  Typography,
  Card,
  Space,
  ColorPicker,
  Spin,
  Select,
} from "antd";
import { ApiOutlined, CopyOutlined, UndoOutlined, SaveOutlined } from "@ant-design/icons";
import ChatBoxPreview from "../../components/ChatBox/ChatBoxPreview";
import { debounce, forEach, isString, map } from "lodash";
import { Base64 } from "js-base64";
import { saveSettingAiAgent } from "../../../services";

const defaultTheme = {
  primaryGradientStart: "#6366f1",
  primaryGradientEnd: "#8b5cf6",
  headerTextColor: "#ffffff",
  chatBgColor: "#ffffff",
  messageSentBg: "#ffffff",
  messageSentText: "#1f2937",
  inputBorderColor: "#e5e7eb",
  messagesBg: "#f8fafc",
};

const colorPickerProps = {
  defaultFormat: "hex",
  size: "small",
};

const LiveChatSettings = ({ aiAgent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scriptContent, setScriptContent] = useState(
    `<script src="http://dalavi-static-files.s3-website-us-east-1.amazonaws.com/live-chat.js"></script>`
  );
  const [form] = Form.useForm();
  const [settings, setSettings] = useState({
    agent: aiAgent?.id || 0,
    title: aiAgent?.name || "Ai Agent",
    themeSettings: { ...defaultTheme },
  });

  const generateScript = useCallback(() => {
    const hash = Base64.encode(JSON.stringify(settings));
    const script = `<script src="http://dalavi-static-files.s3-website-us-east-1.amazonaws.com/live-chat.js" hash="${hash}"></script>`;
    setScriptContent(script);
    setIsGenerating(false);
  }, [settings]);

  const debouncedGenerateScript = useCallback(debounce(generateScript, 1000), [
    generateScript,
  ]);

  useEffect(() => {
    setIsGenerating(true);
    debouncedGenerateScript();
    return () => debouncedGenerateScript.cancel(); // Cleanup để tránh memory leak
  }, [settings, debouncedGenerateScript]);

  const handleSaveSettings = (values) => {
    const newSetting = Object.keys(values).reduce((curr, next) => {
      if(isString(values[next])) {
        curr[next] = values[next];
      } else if(values[next]) {
        curr[next] = values[next].toHexString();
      }
 
      return curr;
    }, {});

    setSettings({
      ...settings,
      themeSettings: newSetting,
    });
    localStorage.setItem(
      Base64.encode(aiAgent?.id || 0),
      JSON.stringify(settings)
    );
  };

  const handleOpenLiveChatSetting = useCallback(() => {
    const loadSettingFromLocal = () => {
      const setting = JSON.parse(
        localStorage.getItem(Base64.encode(aiAgent?.id ?? 0))
      );
      if (setting?.themeSettings) {
        setSettings({
          ...settings,
          themeSettings: setting?.themeSettings,
        });
        form.setFieldsValue({ ...setting?.themeSettings });
      }
    };

    loadSettingFromLocal();

    setIsModalOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    form.resetFields();
  }, []);

  const handleCopyScript = () => {
    navigator.clipboard.writeText(scriptContent);
    message.success("Script copied to clipboard");
  };

  const resetDefaultTheme = () => {
    setSettings({
      agent: aiAgent?.id || 0,
      title: aiAgent?.name || "Ai Agent",
      themeSettings: { ...defaultTheme },
    });

    form.setFieldsValue({ ...defaultTheme });
    localStorage.setItem(
      Base64.encode(aiAgent?.id || 0),
      JSON.stringify(settings)
    );
  };

  const handleSaveSetting = async () => {
    setIsSubmitting(true);
    const payload = {
      id: settings.agent,
      domains: form.getFieldValue("domains"),
      themeSettings: JSON.stringify(settings.themeSettings)
    }

    console.log('payload', payload);

    await saveSettingAiAgent(payload);

    setIsSubmitting(false);
  }

  const handleSaveSettingAndCopy = async () => {
    await handleSaveSetting();
    handleCopyScript();
    handleClose();
  }

  return (
    <>
      <Button
        type="primary"
        icon={<ApiOutlined />}
        onClick={handleOpenLiveChatSetting}
      >
        Setting
      </Button>

      <Modal
        width={900}
        title="Live Chat Settings"
        open={isModalOpen}
        onCancel={handleClose}
        footer={null}
      >
        <Row gutter={16}>
          <Col span={8}>
            <ChatBoxPreview
              title={settings.title}
              themeSettings={settings.themeSettings}
            />
          </Col>
          <Col span={16}>
            <Form
              form={form}
              layout="vertical"
              onValuesChange={() => form.submit()}
              onFinish={handleSaveSettings}
              initialValues={{ ...defaultTheme }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Form.Item
                      label="Primary Gradient Start"
                      name="primaryGradientStart"
                    >
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                    <Form.Item
                      label="Primary Gradient End"
                      name="primaryGradientEnd"
                    >
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                    <Form.Item label="Text Color" name="headerTextColor">
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>

                    <Form.Item label="Chat Background Color" name="chatBgColor">
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Form.Item
                      label="Input Border Color"
                      name="inputBorderColor"
                    >
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                    <Form.Item label="Messages Background" name="messagesBg">
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                    <Form.Item
                      label="Message Sent Background"
                      name="messageSentBg"
                    >
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                    <Form.Item label="Message Sent Text" name="messageSentText">
                      <ColorPicker {...colorPickerProps} />
                    </Form.Item>
                  </Space>
                </Col>

                <Col span={24}>
                  <Form.Item label="Allow access from domains" name="domains">
                    <Select
                      placeholder="domains"
                      mode="tags"
                      allowClear
                    ></Select>
                  </Form.Item>

                  <Space direction="horizontal">
                    <Button type="default" onClick={resetDefaultTheme} disabled={isGenerating || isSubmitting} icon={<UndoOutlined />}>
                      Reset default
                    </Button>

                    <Button type="primary" onClick={handleSaveSetting} disabled={isGenerating || isSubmitting} icon={<SaveOutlined />}>
                      Save setting
                    </Button>

                    <Button type="primary" onClick={handleSaveSettingAndCopy} disabled={isGenerating || isSubmitting} icon={<SaveOutlined />}>
                      Save and copy
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24}>
            <Card className="mt-3">
              {isGenerating && (
                <>
                  <Typography.Paragraph
                    style={{
                      background: "#f5f5f5",
                      padding: 16,
                      borderRadius: 8,
                      maxHeight: "200px",
                      overflow: "auto",
                    }}
                  >
                    <Spin />
                  </Typography.Paragraph>
                </>
              )}
              {!isGenerating && (
                <>
                  <Typography.Paragraph
                    code
                    style={{
                      background: "#f5f5f5",
                      padding: 16,
                      borderRadius: 8,
                      maxHeight: "200px",
                      overflow: "auto",
                    }}
                  >
                    {scriptContent}
                  </Typography.Paragraph>
                </>
              )}

              <Button
                type="primary"
                icon={<CopyOutlined />}
                disabled={isGenerating}
                onClick={handleCopyScript}
              >
                Copy Script
              </Button>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default memo(LiveChatSettings);
