import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Popconfirm, Tooltip, notification } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { isEmpty } from "lodash";
import withErrorHandling from "../../../HOC/withErrorHandler";
import { deletePipe, getPipelines } from "../../../store/pipelineSlice";
import {
  deleteDataRow,
  deleteDatasetItem,
  getDatasetDetailById,
  getDatasetList,
} from "../../../store/datasetSlice";
import { deleteCrawlerById, getCrawlerList } from "../../../store/crawlerSlice";
import {
  deleteAdminActionList,
  getAdminActionList,
} from "../../../store/adminSlice";
import { getAccountUsers } from "../../../store/authSlice";
import { deleteAIContext, deleteUser } from "../../../services";
import { getAIContextList } from "../../../store/aiContextSlice";

const DeleteData = (props) => {
  const { type, data, isReload, text } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");

  useEffect(() => {
    if (type === DELETE_TYPE.DATASET)
      setConfirmTitle(`Are you sure to delete dataset ${data?.name}?`);
    else if (type === DELETE_TYPE.PIPELINE)
      setConfirmTitle(`Are you sure to delete pipeline ${data?.name}?`);
    else if (type === DELETE_TYPE.DATASET_ROW)
      setConfirmTitle(`Are you sure to delete data ?`);
    else if (type === DELETE_TYPE.CRAWLER)
      setConfirmTitle(`Are you sure to delete crawler ${data?.name}?`);
    else if (type === DELETE_TYPE.ADMIN_ACTION)
      setConfirmTitle(`Are you sure to delete action ${data?.actionName}?`);
    else if (type === DELETE_TYPE.CHILD_USER)
      setConfirmTitle(`Are you sure to delete user ${data?.email}?`);
    else if (type === DELETE_TYPE.AI_CONTEXT)
      setConfirmTitle(`Are you sure to delete ai agent ${data?.name}?`);
  }, []);

  const { payload: pipelinePayload } = useSelector(
    (states) => states.pipeline?.pipelinesObj
  );
  const { payload: datasetPayload } = useSelector(
    (states) => states.dataset?.datasetsObj
  );

  const { payload: dataRowsPayload } = useSelector(
    (states) => states.dataset?.datasetObj
  );

  const { payload: crawlerPayload } = useSelector(
    (states) => states.crawler?.crawlersObj
  );

  const { payload: adminActionsPayload } = useSelector(
    (states) => states.admin?.actionsObj
  );

  const { payload: accountUserPayload } = useSelector(
    (states) => states.auth?.user?.childrens
  );

  const { payload: aiContextPayload } = useSelector(
    (states) => states.aicontext?.aicontextObj
  );

  const handleDeleteData = useCallback(
    withErrorHandling(() => {
      switch (type) {
        case DELETE_TYPE.PIPELINE:
          {
            setConfirmLoading(true);
            dispatch(deletePipe(data?.id)).then((res) => {
              if (isReload) {
                dispatch(getPipelines(pipelinePayload));
              }
              setConfirmLoading(false);
              setOpen(false);
            });
          }
          break;
        case DELETE_TYPE.DATASET:
          {
            setConfirmLoading(true);
            dispatch(deleteDatasetItem(data?.id)).then((res) => {
              if (isReload) {
                dispatch(getDatasetList(datasetPayload));
              }
              setConfirmLoading(false);
              setOpen(false);
            });
          }
          break;
        case DELETE_TYPE.DATASET_ROW:
          {
            setConfirmLoading(true);
            dispatch(
              deleteDataRow({
                sourceId: data.datasetId,
                dataIds: [data.id],
              })
            ).then((res) => {
              if (isReload) {
                dispatch(getDatasetDetailById(dataRowsPayload));
              }
              setConfirmLoading(false);
              setOpen(false);
            });
          }
          break;
        case DELETE_TYPE.CRAWLER:
          {
            setConfirmLoading(true);
            dispatch(deleteCrawlerById({ extract_request_id: data?.id })).then(
              (res) => {
                if (isReload) {
                  dispatch(getCrawlerList(crawlerPayload));
                }
                setConfirmLoading(false);
                setOpen(false);
              }
            );
          }
          break;
        case DELETE_TYPE.ADMIN_ACTION:
          {
            setConfirmLoading(true);
            dispatch(deleteAdminActionList({ action_id: data?.id })).then(
              (res) => {
                if (isReload) {
                  dispatch(getAdminActionList(adminActionsPayload));
                }
                setConfirmLoading(false);
                setOpen(false);
              }
            );
          }
          break;
        case DELETE_TYPE.CHILD_USER:
          {
            setConfirmLoading(true);
            deleteUser({ userId: data?.sid }).then((res) => {
              if (isReload) {
                dispatch(getAccountUsers(accountUserPayload));
              }
              setConfirmLoading(false);
              setOpen(false);
            });
          }
          break;
        case DELETE_TYPE.AI_CONTEXT:
          {
            setConfirmLoading(true);
            deleteAIContext({ agent_id: data?.id }).then((res) => {
              if (isReload) {
                dispatch(getAIContextList(aiContextPayload));
              }
              setConfirmLoading(false);
              setOpen(false);
            });
          }
          break;
      }
    })
  );
  const canableDelete = (data) => {
    return !(
      type !== DELETE_TYPE.PIPELINE ||
      (type === DELETE_TYPE.PIPELINE &&
        data.schedules?.filter((schedule) => schedule.status === 1).length ===
          0)
    );
  };
  return (
    <Popconfirm
      title="Warning"
      placement="left"
      description={confirmTitle}
      open={open}
      onConfirm={handleDeleteData}
      okText="Yes"
      cancelText="No"
      onCancel={() => setOpen(false)}
      okButtonProps={{
        loading: confirmLoading,
      }}
    >
      <Tooltip title={text || "Delete"}>
        <Button
          type="primary"
          icon={<DeleteFilled />}
          danger
          onClick={() => setOpen(true)}
          disabled={canableDelete(data)}
        >
          {text}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default memo(DeleteData);

export const DELETE_TYPE = {
  PIPELINE: "PIPELINE",
  DATASET: "DATASET",
  DATASET_ROW: "DATASET_ROW",
  CRAWLER: "CRAWLER",
  ADMIN_ACTION: "ADMIN_ACTION",
  CHILD_USER: "CHILD_USER",
  AI_CONTEXT: "AI_CONTEXT",
};
