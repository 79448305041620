import React, { useEffect, useState } from "react";
import { Avatar, Dropdown } from "antd";
import {
  UnorderedListOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { shortName } from "../../../Utilities";
import { logout } from "../../../store/authSlice";
import { USER_ROLE } from "../../../Authen";

const Menu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);
  const name = shortName(authState.user?.user?.name);
 

  const [menuFiltered, setMenuFiltered] = useState([]);

  useEffect(() => {
    const groupPermission = authState?.user?.user?.groups || [];
    const _menuFiltered = menus.filter(m => {
      const valid = m.auth.some(x => {
        return groupPermission.includes(x);
      })

      return valid;
    });
    setMenuFiltered(_menuFiltered);
  }, [authState])

  return (
    <Dropdown
      menu={{
        items: menuFiltered,
        onClick: (e) => {
          if (e.key !== "logout") {
            navigate(e.key);
          } else {
            dispatch(logout());
          }
        },
      }}
    >
      <Avatar style={{ cursor: "pointer" }} size={40}>
        {name}
      </Avatar>
    </Dropdown>
  );
};

export default Menu;

const menus = [
  // {
  //   label: "Crawlers",
  //   key: "/crawlers",
  //   icon: <UnorderedListOutlined />,
  //   auth: USER_ROLE.USER,
  // },
  // {
  //   label: "Datasets",
  //   key: "/datasets",
  //   icon: <UnorderedListOutlined />,
  //   auth: USER_ROLE.USER,
  // },
  // {
  //   label: "Pipelines",
  //   key: "/pipelines",
  //   icon: <UnorderedListOutlined />,
  //   auth: USER_ROLE.USER,
  // },
  {
    label: "AI Platform",
    key: "/aicontext",
    icon: <UnorderedListOutlined />,
    auth: USER_ROLE.USER,
  },
  {
    label: "Admin",
    key: "/admin/actions",
    icon: <UnorderedListOutlined />,
    auth: USER_ROLE.ADMIN_ONLY,
  },
  {
    label: "Setting",
    key: "/setting",
    icon: <UserOutlined />,
    auth: USER_ROLE.USER,
  },
  {
    label: "Logout",
    key: "logout",
    icon: <LogoutOutlined />,
    auth: USER_ROLE.USER,
  },
];
