import React, { useState, useEffect, useCallback, memo } from "react";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
  EditOutlined,
  SaveFilled,
  RightOutlined,
  LeftOutlined,
  SaveOutlined,
  CopyFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  notification,
} from "antd";
import { delay, isEmpty } from "lodash";
import withErrorHandling from "../../../HOC/withErrorHandler";
import AWSFileUpload from "../../components/AWSFileUpload";
import {
  getAIContextList,
  saveAIContexttItem,
} from "../../../store/aiContextSlice";
import GroupBox from "../../components/GroupBox/GroupBox";

const AIContextDetailModal = ({ data, isCreate, isClone }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [initFiles, setInitFile] = useState([]);
  const [toolSourcename, setToolSourcename] = useState([]);
  const [currTab, setCurrTab] = useState(steps[0]);

  const { payload } = useSelector((states) => states.aicontext?.aicontextObj);
  const tools = useSelector((states) => states.aicontext.tools);
  const { datasets } = useSelector((states) => states.pipeline);

  useEffect(() => {
    const getToolSourcename = () =>
      tools?.map((x) => ({ label: x.name, value: x.id }));
    setToolSourcename(getToolSourcename());
  }, [tools]);

  const showModal = (e) => {
    console.log(
      "tool selected: ",
      data?.aiAgentTools?.filter((x) => !!x)
    );
    form.setFieldsValue({
      id: data?.id,
      name: data?.name,
      description: data?.description,
      urls: data?.urls.filter((x) => !isEmpty(x)),
      content: data?.content.filter((x) => !isEmpty(x)),
      aiAgentTools: data?.aiAgentTools?.filter((x) => !!x), // change tool to object
      datasetId: data?.datasetId,
      datasetAttributes: data?.datasetAttributes.filter((x) => !isEmpty(x)),
    });
    setFileName((data?.file ?? []).filter((x) => !isEmpty(x)));
    setInitFile(
      (data?.file ?? [])
        .filter((x) => !isEmpty(x))
        .map((f, i) => ({
          uid: i,
          name: f,
          status: "done",
        }))
    );
    setOpen(true);
    setCurrTab(steps[0]);
  };

  const handleOk = () => {
    if (currTab === steps[0]) {
      setCurrTab(steps[1]);
      return;
    } else if (currTab === steps[1]) {
      setCurrTab(steps[2]);
      return;
    } else if (currTab === steps[2]) {
      form.submit();
      return;
    }
  };
  const handleCancel = () => {
    if (currTab === steps[0]) {
      setOpen(false);
      setCurrTab(steps[0]);
      return;
    } else if (currTab === steps[1]) {
      setCurrTab(steps[0]);
      return;
    } else if (currTab === steps[2]) {
      setCurrTab(steps[1]);
      return;
    }
  };

  const handleSave = useCallback(
    withErrorHandling((values) => {
      setConfirmLoading(true);
      const params = {
        id: data?.id,
        name: values.name,
        description: values.description,
        urls: values.urls?.filter((x) => !isEmpty(x)),
        content: values.content?.filter((x) => !isEmpty(x)),
        file: fileName.filter((x) => !isEmpty(x)),
        aiAgentTools: values.aiAgentTools,
        datasetId: values?.datasetId,
        datasetAttributes: values?.datasetAttributes?.filter(
          (x) => !isEmpty(x)
        ),
      };

      if (isClone) {
        delete params.id;
      }

      console.log("payload:", params);

      dispatch(saveAIContexttItem(params)).then((res) => {
        notification.success({
          message: `Save AI Agent ${params.name || "No name"} successful`,
        });
        handleClose();
        dispatch(getAIContextList(payload));
      });
    }),
    [form, fileName]
  );

  const handleClose = () => {
    setConfirmLoading(false);
    form.resetFields();
    setFileName([]);
    setCurrTab(steps[0]);
    delay(() => {
      setOpen(false);
    }, 100);
  };

  const handleUploaded = (files) => {
    setFileName([...fileName, files.file.name]);
  };

  const handleOnChangeDataset = (e) => {
    const selected = datasets.find((x) => x.value === e);
    console.log(selected);
    if (selected) {
      form.setFieldsValue({
        datasetAttributes: selected.columns.filter((x) => !isEmpty(x)),
      });
    }
  };

  if (!open) {
    return (
      <Button
        type="primary"
        icon={
          isCreate ? (
            <PlusOutlined />
          ) : isClone ? (
            <CopyFilled />
          ) : (
            <EditOutlined />
          )
        }
        onClick={showModal}
      >
        {isCreate ? "Create" : isClone ? "Clone" : "Edit"}
      </Button>
    );
  }

  return (
    <>
    <Button
        type="primary"
        icon={
          isCreate ? (
            <PlusOutlined />
          ) : isClone ? (
            <CopyFilled />
          ) : (
            <EditOutlined />
          )
        }
        onClick={showModal}
      >
        {isCreate ? "Create" : isClone ? "Clone" : "Edit"}
      </Button>
      
      <Modal
        width={900}
        title={
          isCreate
            ? "Create new AI agent"
            : `Edit AI agent ${data?.name || "No name"}`
        }
        maskClosable={false}
        close
        open={open}
        // onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleClose}
        // okText="Save"
        // okButtonProps={{
        //   icon: <SaveFilled />,
        //   key: "submit",
        // }}
        // cancelButtonProps={{
        //   key: "back",
        //   danger: true,
        // }}
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={{}}
        >
          <Tabs animated={{ inkBar: true, tabPane: true }} activeKey={currTab}>
            <Tabs.TabPane key="general">
              <GroupBox label="General">
                <Form.Item
                  key="aiContextName"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                  key="aiContextDesc"
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="Description" />
                </Form.Item>
              </GroupBox>
            </Tabs.TabPane>

            <Tabs.TabPane key="context">
              <GroupBox label="Context" className="mt-3">
                <Form.Item label="Content" name="content">
                  <Select placeholder="Content" mode="tags"></Select>
                </Form.Item>

                <Form.Item label="List of Websites" name="urls">
                  <Select placeholder="List of Websites" mode="tags"></Select>
                </Form.Item>

                <Form.Item key="aiContextFile" label="File Upload" name="file">
                  <AWSFileUpload
                    onUploaded={handleUploaded}
                    multiple
                    defaultFiles={initFiles}
                    showRemoveIcon={isClone}
                  />
                </Form.Item>

                <Form.Item label="Dataset" name="datasetId">
                  <Select
                    placeholder="Dataset"
                    options={datasets}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleOnChangeDataset}
                  ></Select>
                </Form.Item>

                <Form.Item label="Columns" name="datasetAttributes">
                  <Select placeholder="Columns" mode="tags"></Select>
                </Form.Item>
              </GroupBox>
            </Tabs.TabPane>

            <Tabs.TabPane key="tools">
              <GroupBox label="Ai Agent Tool" className="mt-3">
                <Form.Item label="Tool" name="aiAgentTools">
                  <Select
                    placeholder="Tool"
                    mode="multiple"
                    options={toolSourcename}
                    allowClear
                  ></Select>
                </Form.Item>
              </GroupBox>
            </Tabs.TabPane>
          </Tabs>
          {/* <Divider /> */}
          <Space className="w-full justify-end mt-3">
            {currTab === steps[0] && (
              <>
                <Button key="back" onClick={handleCancel} danger>
                  Cancel
                </Button>

                <Button onClick={handleOk} type="primary">
                  Context <RightOutlined />
                </Button>
              </>
            )}

            {currTab === steps[1] && (
              <>
                <Button onClick={handleCancel} type="primary">
                  <LeftOutlined /> General
                </Button>

                <Button onClick={handleOk} type="primary">
                  Ai Agent Tool <RightOutlined />
                </Button>
              </>
            )}

            {currTab === steps[2] && (
              <>
                <Button onClick={handleCancel} type="primary">
                  <LeftOutlined /> Context
                </Button>

                <Button
                  onClick={handleOk}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </>
            )}
          </Space>
        </Form>
      </Modal>
    </>
  );
};

const steps = ["general", "context", "tools"];

export default AIContextDetailModal;
