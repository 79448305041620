import axios from "axios";
import { store } from "../../store";

export const defaultCancelTokenSource = axios.CancelToken.source();

class AxiosCustom {
  constructor() {
    this.instance = axios.create({
      baseURL: "https://backend.dalavi.io/",
      headers: {
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      cancelToken: defaultCancelTokenSource.token,
    });
    // this.cancelTokenSource = axios.CancelToken.source();
  }

  checkIsLoggedIn() {
    const { isLoggedIn } = this.getAuth();
    return isLoggedIn;
  }

  getAuth() {
    return store.getState().auth;
  }

  init = () => {
    const { user } = this.getAuth();
    // this.instance.defaults.headers.common.Authorization = `Bearer ${config.token}`;
    this.instance.defaults.baseURL = "https://backend.dalavi.io/";
    this.instance.defaults.timeout = 60000;
    this.instance.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "access-token": user.access_token,
    };
    this.instance.defaults.onUploadProgress = (progressEvent) =>
      console.log(progressEvent.loaded);
  };

  isAuthen() {
    if (!this.checkIsLoggedIn()) {
      defaultCancelTokenSource.cancel(
        "Request canceled because of user not login yet"
      );

      return false;
    }

    return true;
  }

  get = (url, params = {}) => {
    if (!this.isAuthen()) return;
    const { user } = this.getAuth();
    return this.instance.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-token": user.access_token,
      },
      params,
    });
  };

  post = (url, data) => {
    if (!this.isAuthen()) return;
    const { user } = this.getAuth();
    return this.instance.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-token": user.access_token,
      },
    });
  };

  putForm = (url, data) => {
    if (!this.isAuthen()) return;
    const { user } = this.getAuth();
    return this.instance.putForm(url, data, {
      headers: {
        "access-token": user.access_token,
      },
    });
  };

  postStream = (url, data) => {
    if (!this.isAuthen()) return;
    const { user } = this.getAuth();
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/event-stream",
        "access-token": user.access_token,
      },
      body: JSON.stringify(data),
    });
  };
}

const axiosCustom = new AxiosCustom();

export default axiosCustom;
