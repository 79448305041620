import React, { useEffect, useRef, useState } from "react";
import axiosCustom from "../../../services/base/axiosCustom";
import { useDispatch, useSelector } from "react-redux";
import { displayChatbox } from "../../../store/aiContextSlice";
import { Base64 } from "js-base64";
const LiveChatBox = () => {
  const dispatch = useDispatch();
  const [messageInput, setMessageInput] = useState("");
  const [show, setShow] = useState(false);
  const [theme, setTheme] = useState({
    primaryGradientStart: "#6366f1",
    primaryGradientEnd: "#8b5cf6",
    headerTextColor: "#ffffff",
    chatBgColor: "#ffffff",
    messageSentBg: "#ffffff",
    messageSentText: "#1f2937",
    inputBorderColor: "#e5e7eb",
    messagesBg: "#f8fafc",
  });
  const messagesEndRef = useRef(null);
  const chatMessagesRef = useRef(null);

  const { open, context } = useSelector((states) => states.aicontext?.chatBox);

  useEffect(() => {
    const loadSettingFromLocal = () => {
      const setting = JSON.parse(
        localStorage.getItem(Base64.encode(context?.id ?? 0))
      );
      if (setting?.themeSettings) {
        setTheme(setting?.themeSettings);
      } else if (context?.themeSettings) {
        setTheme(JSON.parse(context?.themeSettings));
      } else {
        setTheme({
          primaryGradientStart: "#6366f1",
          primaryGradientEnd: "#8b5cf6",
          headerTextColor: "#ffffff",
          chatBgColor: "#ffffff",
          messageSentBg: "#ffffff",
          messageSentText: "#1f2937",
          inputBorderColor: "#e5e7eb",
          messagesBg: "#f8fafc",
        });
      }
      console.log("loadSettingFromLocal", context, setting, theme);
    };

    loadSettingFromLocal();
    chatMessagesRef.current.innerHTML = "";
    setShow(open);
  }, [open]);

  const toggleChat = () => {
    dispatch(displayChatbox({ open: false, context: {} }));
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await sendMessage();
    }
  };

  const sendMessage = async () => {
    const message = messageInput.trim();

    if (message) {
      addMessage(message, "sent");
      setMessageInput("");

      addTypingIndicator();
      await generateAIStreamResponse(message);
    }
  };

  const addTypingIndicator = (message) => {
    const typingDiv = document.createElement("div");
    typingDiv.className = "message received";
    typingDiv.id = "typing-indicator";
    const indicatorContent = document.createElement("div");
    indicatorContent.className = "typing-indicator";

    if(message) {
      const messageDiv = document.createElement("div");
      messageDiv.className = `typing-text`;
      messageDiv.textContent = message;
      indicatorContent.appendChild(messageDiv);

      const loadingContainer = document.createElement("div");
      loadingContainer.className = "loading-container";
      const loadingCircle = document.createElement("div");
      loadingCircle.className = "loading-circle";
      loadingContainer.appendChild(loadingCircle);
      indicatorContent.appendChild(loadingContainer);
    } else {
      for (let i = 0; i < 3; i++) {
        const dot = document.createElement("div");
        dot.className = "typing-dot";
        indicatorContent.appendChild(dot);
      }
    }

    typingDiv.appendChild(indicatorContent);
    chatMessagesRef.current.appendChild(typingDiv);
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
  };

  const removeTypingIndicator = () => {
    const typingIndicator =
      chatMessagesRef.current.querySelector("#typing-indicator");
    if (typingIndicator) {
      typingIndicator.remove();
    }
  };

  const generateAIResponse = async (userMessage) => {
    try {
      const result = await axiosCustom.postStream(
        "https://backend.dalavi.io/ai_agent/prompt",
        {
          aiAgentId: context?.id,
          question: userMessage,
          chatHistory: [], // TODO: save history serveral context
        }
      );
      addMessage(result?.data, "received");
    } catch (error) {
      console.error("Failed to send data:", error);
      addMessage("Something went wrong!", "received");
    } finally {
      removeTypingIndicator();
    }
  };

  const generateAIStreamResponseV2 = async (userMessage) => {
    
    try {
      const eventSource = new EventSource("https://backend.dalavi.io/ai_agent/prompt"); // Adjust API URL
      
      eventSource.onmessage = (event) => {
          const responseData = JSON.parse(event.data);
          console.log('received event: ', responseData);
      };

      eventSource.onerror = (error) => {
          console.error("Streaming error:", error);
          eventSource.close();
      };

    } catch (error) {
        console.error("Error sending message:", error);
    }
  }

  const generateAIStreamResponse = async (userMessage) => {
    try {
      const response = await axiosCustom.postStream(
        "https://backend.dalavi.io/ai_agent/prompt",
        {
          aiAgentId: context?.id,
          question: userMessage,
          chatHistory: [], // TODO: save history serveral context
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch SSE");
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const text = decoder.decode(value, { stream: true });
        for (const messageJson of extractMessages(text)) {
          if(messageJson.status === 'completed')
            addMessage(messageJson.message, "received");
          else {
            toggleTypingIndicator(messageJson.message);
          }
        }
        
      }

    } catch (error) {
      console.error("Failed to send data:", error);
      addMessage("Something went wrong!", "received");
    } finally {
      removeTypingIndicator();
    }
  };

  const toggleTypingIndicator = (message) => {
    removeTypingIndicator();
    addTypingIndicator(message);
  }

  function* extractMessages(dataString) {
    const regex = /data:({.*?})/g;
    let match;

    while ((match = regex.exec(dataString)) !== null) {
      try {
        const jsonData = JSON.parse(match[1]);
        console.log('json message:', jsonData);
        // if(jsonData.status === 'completed')
        //   yield jsonData.message;
        yield jsonData;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }

  const addMessage = (text, type) => {
    const messageDiv = document.createElement("div");
    messageDiv.className = `message ${type}`;
    
    if (type === "received") {
      const messageContent = document.createElement("div");
      messageContent.className = "message-content";
      messageContent.textContent = text;
      messageDiv.appendChild(messageContent);
      
      // Add typing animation for received messages
      let currentText = "";
      const words = text.split("");
      let i = 0;
      
      const typeWriter = () => {
        if (i < words.length) {
          currentText += words[i];
          messageContent.textContent = currentText;
          i++;
          setTimeout(typeWriter, 20); // Adjust typing speed here
        }

        chatMessagesRef.current.scrollTo({
          top: chatMessagesRef.current.scrollHeight,
          behavior: "smooth",
        });
      };
      
      typeWriter();
    } else {
      messageDiv.textContent = text;
    }

    chatMessagesRef.current.appendChild(messageDiv);

    const clearDiv = document.createElement("div");
    clearDiv.style.clear = "both";
    chatMessagesRef.current.appendChild(clearDiv);

    chatMessagesRef.current.scrollTo({
      top: chatMessagesRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="chat-container"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="chat-header">
          <span style={{ fontWeight: 600, fontSize: "16px" }}>
            {context.name || "Ai agent"}
          </span>
          <span
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={toggleChat}
          >
            ×
          </span>
        </div>

        <div className="chat-messages" ref={chatMessagesRef}>
          <div ref={messagesEndRef} />
        </div>

        <div className="chat-input">
          <input
            type="text"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Ask me anything..."
            onKeyPress={handleKeyPress}
          />
          <button onClick={sendMessage}>➤</button>
        </div>

        <div className="powered-by">
          <span>Powered by</span>
          <span style={{position: 'relative'}}>
            <span className="powered-by-logo"> Dalavi</span>
          </span>
        </div>
      </div>

      <style>
        {`
          :root {
            --primary-gradient-start: ${
              theme.primaryGradientStart || "#6366f1"
            };
            --primary-gradient-end: ${theme.primaryGradientEnd || "#8b5cf6"};
            --header-text-color: ${theme.headerTextColor || "white"};
            --chat-bg-color: ${theme.chatBGColor || "#ffffff"};
            --message-received-bg: white;
            --message-received-text: #1f2937;
            --message-sent-bg-start: ${theme.messageSentBg || "#ffffff"};
            --message-sent-bg-end: ${theme.messageSentBg || "#ffffff"};
            --message-sent-text: ${theme.messageSentText || "#ffffff"};
            --input-placeholder-color: #6b7280;
            --input-border-color: ${theme.inputBorderColor || "#e5e7eb"};
            --input-focus-border: var(--primary-gradient-start);
            --button-bg-start: var(--primary-gradient-start);
            --button-bg-end: var(--primary-gradient-end);
            --button-text: ${theme.headerTextColor || "white"};
            --toggle-bg-start: var(--primary-gradient-start);
            --toggle-bg-end: var(--primary-gradient-end);
            --toggle-text: ${theme.headerTextColor || "white"};
            --toggle-icon: '💬';
            --toggle-bg: linear-gradient(135deg, var(--toggle-bg-start) 0%, var(--toggle-bg-end) 100%);
            --powered-by-text: #6b7280;
            --messages-bg: ${theme.messagesBg || "#f8fafc"};
          }

          .chat-container {
            position: fixed;
            bottom: 20px;
            right: 20px;
            width: 350px;
            height: 500px;
            background: var(--chat-bg-color);
            border-radius: 20px;
            box-shadow: 0 8px 24px rgba(0,0,0,0.15);
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            overflow: hidden;
            animation: slideIn 0.3s ease-out;
          }

          .chat-header {
            background: linear-gradient(135deg, var(--primary-gradient-start) 0%, var(--primary-gradient-end) 100%);
            color: var(--header-text-color);
            padding: 20px;
            border-radius: 20px 20px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
          }

          .chat-header-logo {
            padding: 5px;
            background-color: #fff;
            border-radius: 50%;
            border: "2px solid #fff";
            width: 20px;
          }

          .chat-messages {
            height: 300px;
            padding: 20px;
            overflow-y: auto;
            background: var(--messages-bg);
          }

          .message {
            margin: 8px 0;
            padding: 12px 18px;
            border-radius: 18px;
            max-width: 75%;
            word-wrap: break-word;
            line-height: 1.4;
            position: relative;
            animation: fadeIn 0.3s ease-in-out;
          }

          .received {
            background: linear-gradient(135deg, var(--primary-gradient-start) 0%, var(--primary-gradient-end) 100%);
            color: var(--header-text-color);
            box-shadow: 0 2px 5px rgba(0,0,0,0.05);
            float: left;
            border-bottom-left-radius: 4px;
            animation: slideFromLeft 0.3s ease-out;
          }

          .message-content {
            min-height: 20px;
          }

          .sent {
            background: linear-gradient(135deg, var(--message-sent-bg-start) 0%, var(--message-sent-bg-end) 100%);
            color: var(--message-sent-text);
            float: right;
            border-bottom-right-radius: 4px;
            animation: slideFromRight 0.3s ease-out;
          }

          .chat-input {
            position: absolute;
            bottom: 30px;
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            background: var(--chat-bg-color);
            border-top: 1px solid var(--input-border-color);
          }

          .chat-input input {
            width: 85%;
            padding: 12px 20px;
            border: 2px solid var(--input-border-color);
            border-radius: 25px;
            margin-right: 10px;
            font-size: 14px;
            transition: all 0.3s ease;
            outline: none;
          }

          .chat-input button {
            width: 15%;
            background: linear-gradient(135deg, var(--button-bg-start) 0%, var(--button-bg-end) 100%);
            color: var(--button-text);
            border: none;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.2s ease;
            font-size: 16px;
          }

          .typing-indicator {
            display: flex;
            gap: 4px;
            padding: 4px 8px;
            align-items: baseline;
          }

          .typing-dot {
            width: 5px;
            height: 5px;
            background: var(--header-text-color);
            border-radius: 50%;
            animation: typingBounce 1s infinite;
          }

          .typing-text {
            margin-right: 5px;
          }

          /* 🔵 Circular Loading Animation */
          .loading-container {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
          }

          .loading-circle {
            width: 15px;
            height: 15px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-left-color: #fff;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
          }

          .powered-by {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 8px;
            font-size: 12px;
            color: var(--powered-by-text);
            background: var(--chat-bg-color);
            border-top: 1px solid var(--input-border-color);
          }

          .powered-by-logo {
            margin-left: 30px;
          }

          .powered-by-logo::before {
            content: '';
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-image: url('https://dalavi.io/assets/images/LOGO_2.png');
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: center;
          }

          .chat-toggle {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background: var(--toggle-bg);
            color: var(--toggle-text);
            padding: 18px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 4px 12px rgba(0,0,0,0.15);
            transition: all 0.3s ease;
            font-size: 20px;
            animation: pulse 2s infinite;
          }

          .chat-toggle::before {
            content: var(--toggle-icon);
          }

          @keyframes slideIn {
            from { transform: translateY(100%); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }

          @keyframes pulse {
            0% { transform: scale(1); box-shadow: 0 4px 12px rgba(0,0,0,0.15); }
            50% { transform: scale(1.05); box-shadow: 0 6px 16px rgba(99, 102, 241, 0.3); }
            100% { transform: scale(1); box-shadow: 0 4px 12px rgba(0,0,0,0.15); }
          }

          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
          }

          @keyframes slideFromLeft {
            from { transform: translateX(-20px); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }

          @keyframes slideFromRight {
            from { transform: translateX(20px); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }

          @keyframes typingBounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-4px); }
          }
        `}
      </style>
    </>
  );
};

export default LiveChatBox;
