import React, { lazy } from "react";
import { USER_ROLE } from "../Authen";
import Pipeline from "../pages/Pipeline";
import PipelineList from "../pages/PipelineList";
import DatasetList from "../pages/DatasetList/DatasetList";
import DataDetail from "../pages/DataDetail/DataDetail";
import CrawlerList from "../pages/CrawlerList/CrawlerList";
import DatastoreList from "../pages/DatastoreList/DatastoreList";
import CrawlerDetail from "../pages/CrawlerDetail/CrawlerDetail";
import ActionList from "../pages/ActionList/ActionList";
import ActionDetail from "../pages/ActionDetail/ActionDetail";
import HomePage from "../pages/Front/HomePage/HomePage";
import Extraction from "../pages/Front/Extraction";
import ExtractionPreview from "../pages/Front/Preview";
import SettingDashboard from "../pages/Settings/SettingDashboard";
import PaymentSuccess from "../pages/Settings/PaymentSuccess";
import AIContextList from "../pages/AIContext/AIContextList";

export const menuItems = [
  {
    auth: USER_ROLE.USER,
    anonymous: true,
    key: "default",
    path: "/",
    label: "Default",
    element: <HomePage />,
    breadcrumb: ["home"],
    hidden: true,
  },
  {
    key: "home",
    label: "Home",
    path: "/",
    element: <HomePage />,
    auth: USER_ROLE.USER,
    anonymous: true,
    breadcrumb: ["home"],
  },
  {
    key: "data-platform",
    label: "AI Platform",
    breadcrumb: ["home"],
    children: [
      {
        key: "crawlers",
        label: "Crawler",
        path: "/crawlers",
        // path: "http://dalavi.io/#!/data/crawler",
        element: <CrawlerList />,
        auth: USER_ROLE.USER,
      },
      {
        key: "crawlers-crawlerId",
        label: "Crawler detail",
        path: "/crawlers/:crawlerId",
        element: <CrawlerDetail />,
        auth: USER_ROLE.USER,
        hidden: true,
        breadcrumb: ["home", "crawlers", "crawlers-crawlerId"],
      },
      {
        key: "crawlers-brower-crawlerId",
        label: "Extraction",
        path: "/crawlers/brower/:crawlerId",
        element: <Extraction />,
        auth: USER_ROLE.USER,
        hidden: true,
        breadcrumb: [
          "home",
          "crawlers",
          // "crawlers-crawlerId",
          "crawlers-brower-crawlerId",
        ],
      },
      {
        key: "extract-brower",
        label: "Extraction",
        path: "/extraction",
        element: <Extraction />,
        auth: USER_ROLE.USER,
        anonymous: true,
        hidden: true,
        breadcrumb: ["home", "extract-brower"],
      },
      {
        key: "extract-preview",
        label: "Preview",
        path: "/extraction/preview",
        element: <ExtractionPreview />,
        auth: USER_ROLE.USER,
        anonymous: true,
        hidden: true,
        breadcrumb: ["home", "extract-preview"],
      },
      {
        key: "datasets",
        label: "Dataset",
        path: "/datasets",
        element: <DatasetList />,
        auth: USER_ROLE.USER,
        breadcrumb: ["home", "datasets"],
      },
      {
        key: "datasets-datasetId",
        label: "View Data",
        path: "/datasets/:datasetId",
        element: <DataDetail />,
        auth: USER_ROLE.USER,
        hidden: true,
        breadcrumb: ["home", "datasets", "datasets-datasetId"],
      },
      {
        key: "pipelines",
        label: "Pipeline",
        path: "/pipelines",
        element: <PipelineList />,
        auth: USER_ROLE.USER,
        breadcrumb: ["home", "pipelines"],
      },
      {
        key: "pipeline-pipelineId",
        label: "Pipeline Detail",
        path: "/pipelines/:pipelineId",
        element: <Pipeline />,
        auth: USER_ROLE.USER,
        hidden: true,
        breadcrumb: ["home", "pipelines", "pipeline-pipelineId"],
      },
      {
        key: "aicontext",
        label: "AI Agent",
        path: "/aicontext",
        element: <AIContextList />,
        auth: USER_ROLE.ADMIN_ONLY,
        breadcrumb: ["home", "aicontext"],
      },
    ],
  },
  {
    key: "admin",
    label: "Administrator",
    children: [
      {
        key: "actions",
        label: "Actions",
        path: "/admin/actions",
        // path: "http://dalavi.io/#!/admin/actions",
        element: <ActionList />,
        auth: USER_ROLE.ADMIN_ONLY,
        breadcrumb: ["home", "actions"],
      },
      {
        key: "actions-actionId",
        label: "Action Detail",
        path: "/admin/actions/:actionId",
        element: <ActionDetail />,
        auth: USER_ROLE.USER,
        hidden: true,
        breadcrumb: ["home", "actions", "actions-actionId"],
      },
    ],
  },
  {
    key: "account",
    label: "Setting",
    path: "setting",
    element: <SettingDashboard /> ,
    auth: USER_ROLE.USER,
    breadcrumb: ["setting"],
  },
  {
    key: "payment-status",
    label: "Payment Result",
    path: "return",
    element: <PaymentSuccess /> ,
    auth: USER_ROLE.USER,
    anonymous: true,
    breadcrumb: [],
    hidden: true
  }
];

const routerConfigs = [
  {
    auth: USER_ROLE.USER,
    key: "default",
    path: "/",
    label: "Default",
    element: <PipelineList />,
    breadcrumb: ["home", "pipelines"],
  },
  {
    auth: USER_ROLE.USER,
    anonymous: true,
    key: "home",
    path: "http://dalavi.io",
    label: "Home",
    breadcrumb: ["home"],
  },
  {
    auth: USER_ROLE.USER,
    key: "pipeline-pipelineId",
    path: "/pipelines/:pipelineId",
    label: "Pipeline Detail",
    element: <Pipeline />,
    breadcrumb: ["home", "pipelines", "pipeline-pipelineId"],
  },
  {
    auth: USER_ROLE.USER,
    key: "pipelines",
    path: "/pipelines",
    label: "Pipeline",
    element: <PipelineList />,
    breadcrumb: ["home", "pipelines"],
  },
  {
    auth: USER_ROLE.USER,
    key: "actions",
    path: "/admin/actions",
    label: "Action",
    element: () => <>Not found</>,
    breadcrumb: ["home", "action"],
  },
];
