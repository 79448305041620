import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, createAIContext, getAIContexts, getAIContextTools } from "../services";

export const getAIContextList = createAsyncThunk(
  API_URL.AI_CONTEXT_LIST,
  async (params) => {
    const response = await getAIContexts(params);
    return response.data;
  }
);

export const saveAIContexttItem = createAsyncThunk(
  API_URL.AI_CONTEXT_CRE,
  async (params) => {
    const response = await createAIContext(params);
    return response.data;
  }
);

export const getAIContextTool = createAsyncThunk(
  API_URL.AI_CONTEXT_TOOLS,
  async (params) => {
    const response = await getAIContextTools(params);
    return response.data;
  }
);

const initialState = {
  tools: [],
  aicontextObj: {
    list: [],
    total: 0,
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  chatBox: {
    open: false,
    context: {},
  }
};

export const aiContextSlice = createSlice({
  name: "aiContextSlice",
  initialState,
  reducers: {
    setPayloadGetAIContextList: (state, { payload }) => ({
      ...state,
      aicontextObj: {
        ...state.aicontextObj,
        payload: payload,
      },
    }),
    displayChatbox: (state, { payload : { open, context } }) => ({
      ...state,
        chatBox: {
          open: open,
          context: context,
        },
    })
  },
  extraReducers: {
    [getAIContextList.fulfilled]: (state, { payload }) => {
      state.aicontextObj = {
        ...state.aicontextObj,
        list: payload.lstAiContext,
        total: payload.total,
      };
    },
    [getAIContextTool.fulfilled]: (state, {payload}) => {
      state.tools = payload;
    },
  },
});

export const { setPayloadGetAIContextList, displayChatbox } = aiContextSlice.actions;

export default aiContextSlice.reducer;
