import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  MiniMap,
  Background,
  MarkerType,
  applyNodeChanges,
  applyEdgeChanges,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";
import { ReloadOutlined, RollbackOutlined } from "@ant-design/icons";
import isNumber from "lodash/isNumber";
import { clone, delay, isEmpty, max } from "lodash";

import { PipelineContext, RouteConfigContext } from "../../context";

import DynamicComponentModal from "./DynamicComponentModal";
import Sidebar from "./ActionPipe/sidebar";

import "./index.css";
import TextUpdaterNode from "./Nodes/TextUpdaterNode";
import PipeNode from "./Nodes/PipeNode";
import DefaultPipeNode from "./Nodes/DefaultPipeNode";
import FloatingEdge from "./Edges/FloatingEdge";
import * as until from "./utils";
import BlockUI from "../components/BlockUI";
import PipelineInfo from "./PipelineInfo";
import {
  allCrawlers,
  allDataset,
  selectActionData,
  selectPipe,
  setOutputColumns,
  setOutputFromDataSource,
  resetDynamicForm,
  getPipelineDetailById,
  allAiAgent,
} from "../../store/pipelineSlice";
import { tryParse, tryParseInt } from "../../Utilities";
import { Button } from "antd";

const nodeTypes = {
  textUpdater: TextUpdaterNode,
  pipeNode: PipeNode,
  DefaultPipeNode: DefaultPipeNode,
};
const edgeTypes = {
  floating: FloatingEdge,
};

const Pipeline = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { customCurrentPageName, setCustomCurrentPageName } =
    useContext(RouteConfigContext);
  const { pipelineId } = useParams();
  const reactFlowWrapper = useRef(null);

  const { crawlers, datasets, aiAgents, selectedPipe, pipelineRender } =
    useSelector((states) => states.pipeline);

  const [loading, setLoading] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [title, setTitle] = useState();

  // chart
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState();

  useEffect(() => {
    const parsed = tryParseInt(pipelineId);
    if (!parsed.succeed) return;

    setLoading(true);
    dispatch(getPipelineDetailById({ pipeline_id: parsed.data }));
  }, [pipelineId]);

  useEffect(() => {
    if (isEmpty(pipelineRender)) return;
    const customPageName = {
      ...customCurrentPageName,
      [`/pipelines/${pipelineId}`]: pipelineRender?.name,
    };
    setCustomCurrentPageName(customPageName);

    let responseData = JSON.parse(pipelineRender.pipelineMetadata);
    if (pipelineRender.pipelineRuns && pipelineRender.pipelineRuns.length) {
      // calculate status before set to obj
      let lastedRunId = max(
        pipelineRender.pipelineRuns
          .filter((x) => x.status !== "CANCEL")
          .map((x) => x.id)
      );
      let lastedRun = pipelineRender.pipelineRuns.find(
        (x) => x.id === lastedRunId
      );
      // setLatestRunPipeline(lastedRun);
      responseData.nodes.map((node) => (node.type = "DefaultPipeNode"));
      if (lastedRun && lastedRun.status != "COMPLETED") {
        // setIsPipelineRunning(true);
        let lastedPipeRuns = pipelineRender.actionRequests.map((x) => {
          let actionRequestCloned = clone(x);
          if (
            actionRequestCloned?.actionRequestRunSet &&
            actionRequestCloned?.actionRequestRunSet?.length > 0
          ) {
            actionRequestCloned.actionRequestRunSet =
              actionRequestCloned.actionRequestRunSet?.filter(
                (y) => y?.pipelineRunId === lastedRunId
              );
          }
          return actionRequestCloned;
        });
        responseData.nodes.map((pipe) => {
          let log = lastedPipeRuns.find((x) => x.idRender === pipe.idRender);
          if (!log || !log.actionRequestRunSet.length) return pipe;

          pipe.type = "pipeNode";
          pipe.data.actionRequestRunSet = log.actionRequestRunSet;
          let connLog = responseData.edges.find((x) => x.source == pipe.id);

          if (log.actionRequestRunSet[0].status != "COMPLETED") {
            pipe.data.status = "INPROGRESS";

            if (connLog) {
              connLog.animated = true;
              connLog.style = { stroke: "blue", strokeWidth: 2 };
              connLog.markerEnd.color = "blue";
            }
          } else {
            pipe.data.status = "COMPLETED";

            if (connLog) {
              connLog.style = { stroke: "green", strokeWidth: 2 };
              connLog.markerEnd.color = "green";
            }
          }

          return pipe;
        });
      }
    }

    setNodes(responseData.nodes);
    setEdges(responseData.edges);
    delay(() => {
      setLoading(false);
    }, 2000);

    // if (pipelineRender.schedule) {
    //   setCronValue(pipelineRender.schedule.crons || cronValue);
    //   setShowCronTab(true);
    // }
  }, [pipelineRender]);

  useEffect(() => {
    dispatch(allCrawlers());
    dispatch(allDataset());
    dispatch(allAiAgent());
  }, [dispatch]);

  useEffect(() => {
    buildingAction();
  }, [selectedNode]);
  // const onConnect = (connection) => setEdges((eds) => addEdge({ ...connection, type: 'floating', markerEnd: { type: MarkerType.ArrowClosed } }, eds) );
  const onConnect = (params) => {
    return setEdges((eds) =>
      addEdge(
        {
          ...params,
          type: "floating",
          markerEnd: { type: MarkerType.ArrowClosed },
        },
        eds
      )
    );
  };
  const onInit = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const data = JSON.parse(
        event.dataTransfer.getData("application/reactflow")
      );

      // check if the dropped element is valid
      if (typeof data === "undefined" || !data) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      setNodes((prevNodes) => {
        const id = until.generateUUID();
        const newNode = {
          id: id,
          idRender: id,
          type: "DefaultPipeNode",
          position,
          data: { label: `${data.actionDisplayName}`, action: data },
        };
        return [...prevNodes, newNode];
      });
    },
    [reactFlowInstance]
  );

  const onSelectNode = useCallback((obj) => {
    const selectedNode = obj?.nodes || [];
    if (!selectedNode || !selectedNode.length || loading) {
      dispatch(selectPipe(null));
      setSelectedNode(null);
      setShowSetting(false);
      dispatch(resetDynamicForm());
      return;
    }
    dispatch(selectPipe(selectedNode[0]));
    setSelectedNode(selectedNode[0]);
    setShowSetting(true);
  }, [loading]);

  const getPreviousPipes = (targetPipeId) => {
    let pipeConnectionIds = edges
      .filter((x) => x.target == targetPipeId)
      .map((x) => x.source);

    if (pipeConnectionIds && pipeConnectionIds.length) {
      return nodes.filter((x) => pipeConnectionIds.includes(x.idRender));
    }

    return [];
  };

  const getPreviousPipe = (targetPipeId) => {
    let pipeConnection = edges.find((x) => x.target == targetPipeId);

    if (pipeConnection) {
      return nodes.find((x) => x.idRender == pipeConnection.source);
    }

    return null;
  };

  const getPreviousPipeOutputDataSource = (targetPipeId) => {
    let pipeSource = getPreviousPipe(targetPipeId);

    if (pipeSource && pipeSource.data.selectedOutputColumns) {
      return pipeSource.data.selectedOutputColumns?.map((x) => {
        return { label: x, value: x };
      });
    }

    return [];
  };

  const buildingAction = useCallback(() => {
    if (!selectedNode) return;

    let action = JSON.parse(selectedNode.data.action.actionMetadata);

    action.forEach((row) => {
      row.columns.forEach((col) => {
        col.components.forEach((c) => {
          // migrate to new version pipeline
          if (
            !isEmpty(c[c.apiProperty]) &&
            typeof c[c.apiProperty] === "string" &&
            c[c.apiProperty].includes("||")
          ) {
            c[c.apiProperty] = c[c.apiProperty].split("||");
          }

          c.isValid = true;
          const numberOfPreviousPipe = edges.filter(
            (x) => x.target == selectedNode.id
          ).length;

          if (c.type === "select") {
            if (c.dataSouceType === "dataset") {
              c.values = datasets;
            }
            if (c.dataSouceType === "crawler") {
              c.values = crawlers;
            }
            if (c.dataSouceType === "aiagent") {
              c.values = aiAgents;
            }
            if (c.dataSouceType === "column" && numberOfPreviousPipe == 1) {
              c.values = getPreviousPipeOutputDataSource(selectedNode.id);
            }
            if (c.dataSouceType === "column" && numberOfPreviousPipe > 1) {
              c.getDatasetSourceFrom = getPreviousPipes(selectedNode.id);
              c.values = [];
            }
          } else if (c.type == "dataSourceMapping") {
            if (c.fromDataSourceType == "dataset") {
              c.fromDatasourceValues = datasets;
            }
            if (c.fromDataSourceType == "crawler") {
              c.fromDatasourceValues = crawlers;
            }
            if (c.fromDataSourceType == "aiagent") {
              c.fromDatasourceValues = aiAgents;
            }
            if (c.toDataSourceType == "dataset") {
              c.toDatasourceValues = datasets;
            }
            if (c.toDataSourceType == "crawler") {
              c.toDatasourceValues = crawlers;
            }
            if (c.toDataSourceType == "aiagent") {
              c.toDatasourceValues = aiAgents;
            }
          } else if (c.type == "renameMultipleColumns") {
            let previousActionsColumns = [];

            if (numberOfPreviousPipe == 1) {
              previousActionsColumns = getPreviousPipeOutputDataSource(
                selectedNode.id
              );
            }

            if (numberOfPreviousPipe > 1) {
              c.getDatasetSourceFrom = getPreviousPipes(selectedNode.id);
              previousActionsColumns = c[c.apiProperty]?.map((x) => {
                return { label: x.source, value: x.source };
              });
            }

            const apiComponentData = tryParse(c[c.apiProperty], []).data;
            const renameObj = previousActionsColumns.map((x) => {
              const mapped = apiComponentData?.find((m) => m.source == x.value);
              return {
                source: x.value,
                target: mapped ? mapped.target : x.value,
                isNew: !mapped,
              };
            });

            c[c.apiProperty] = renameObj;
            c[`__${c.apiProperty}_str`] = JSON.stringify(renameObj);
          }
        });
      });
    });
    dispatch(selectActionData([...action]));
    getOutputFromDataSource([...action]);
    dispatch(
      setOutputColumns({
        selectedOutputFrom: selectedNode.data.selectedOutputFrom,
        selectedOutputColumns: selectedNode.data.selectedOutputColumns,
      })
    );
  }, [selectedNode]);

  const getOutputFromDataSource = useCallback((actionData) => {
    const outputDataSourceCloned = actionData.flatMap((row) =>
      row.columns.flatMap((col) =>
        col.components.flatMap((component) => {
          if (
            (component.type === "select" || component.type === "taginputs") &&
            (component.dataSouceType === "dataset" ||
              component.dataSouceType === "crawler" ||
              component.dataSouceType === "aiagent")
          ) {
            return component;
          }

          if (component.type === "renameMultipleColumns") {
            return component;
          }

          if (component.type === "dataSourceMapping") {
            const fromC = { ...component, isFromSource: true };
            const toC = { ...component, isFromSource: false };
            return [fromC, toC];
          }

          return [];
        })
      )
    );

    dispatch(setOutputFromDataSource(outputDataSourceCloned));
  }, []);

  const handleCloseSetting = () => {
    setShowSetting(false);
  };

  const handleReload = () => {
    navigator(0);
  };

  const handleOnSaveSetting = useCallback((actions, settings) => {
    let nodesCloned = JSON.parse(JSON.stringify(nodes));
    const nodesSave = nodesCloned.map((node) => {
      if (node.idRender === selectedNode.idRender) {
        node = JSON.parse(JSON.stringify(selectedNode));
        node.data.action.actionMetadata = JSON.stringify(actions);
        node.data.selectedOutputColumns = settings.selectedOutputColumns;
        node.data.selectedOutputFrom = settings.selectedOutputFrom;
        node.data.label = settings.label;
      }
      setShowSetting(false);
      return node;
    });
    setNodes(nodesSave);
  });

  const pipelineContext = {
    nodes,
    edges,
    setNodes,
    setEdges,
    selectedNode,
    onSelectNode,
    showSetting,
    setShowSetting,
    setSubmiting,
  };

  return (
    <PipelineContext.Provider value={pipelineContext}>
      <div
        style={{
          padding: 10,
          display: "inline-flex",
          justifyContent: "start",
          gap: 10,
          width: "100%",
        }}
      >
        <Button
          type="default"
          icon={<RollbackOutlined />}
          onClick={() => {
            navigator("/pipelines");
          }}
        >
          Back to List
        </Button>
        <Button type="default" icon={<ReloadOutlined />} onClick={handleReload}>
          Reload
        </Button>
      </div>
      <div className="dndflow">
        <DynamicComponentModal
          open={showSetting}
          onClose={handleCloseSetting}
          onSave={handleOnSaveSetting}
        />

        <div className="flex relative">
          <Sidebar />
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                // connectionLineComponent={FloatingConnectionLine}
                nodes={nodes}
                edges={edges}
                defaultMarkerColor="#000"
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={onInit}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onSelectionChange={onSelectNode}
                attributionPosition="top-right"
              >
                <Background variant="lines" gap={12} size={4} />
                <Controls />
                <MiniMap />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
          <PipelineInfo nodes={nodes} edges={edges} />
        </div>
        <BlockUI blocking={submiting || loading} title={title} />
      </div>
    </PipelineContext.Provider>
  );
};

export default Pipeline;
