import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  notification,
} from "antd";
import {
  ShareAltOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";

import FormRenderModal from "../FormBuilder/Render/FormRenderModal";
import { FormDynamicContext } from "../../../context/FormDynamicContext";
import FormRender, { FORM_RENDER_TYPE } from "../FormBuilder/Render/FormRender";
import { useNavigate } from "react-router-dom";

const DropdownAction = (props) => {
  const navigator = useNavigate();
  const { type, data } = props;
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [formDynamicContext, setFormDynamicContext] = useState({});
  const [actionsSource, setActionsSource] = useState([]);

  const { actionsDataset, actionsAiAgent, actions } = useSelector((states) => states.admin);

  useEffect(() => {
    if(type === ACTION_TYPE.DATASET) setActionsSource(actionsDataset);
    if(type === ACTION_TYPE.AIAGENT) setActionsSource(actionsAiAgent);

    setLoaded(true);
  }, [actionsDataset, actionsAiAgent]);

  useEffect(() => {
    if (loaded || isEmpty(actionsSource)) return;
    setLoaded(true);
  }, [actionsSource]);

  const onClick = ({ key }) => {
    const foundAction = actions.find(
      (x) => `action_${x.actionCategory}_${x.id}` === key
    );
    setFormDynamicContext({
      ...formDynamicContext,
      actionSelected: foundAction,
    });
    setOpen(true);
  };

  const handleClose = (event, isReload) => {
    setFormDynamicContext({
      ...formDynamicContext,
      actionSelected: {},
    });
    setOpen(false);

    if (isReload) {
      navigator(0);
    }
  };

  if (!loaded)
    return (
      <Button type="primary" loading>
        Action
      </Button>
    );

  return (
    <FormDynamicContext.Provider value={formDynamicContext}>
      <Dropdown
        menu={{
          items: actionsSource,
          onClick,
        }}
      >
        <Button type="primary">
          <Space>
            Action
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      <FormRender
        type={FORM_RENDER_TYPE.MODAL}
        open={open}
        onCancel={handleClose}
        currentData={data}
        dataType={type}
      />
    </FormDynamicContext.Provider>
  );
};

export default memo(DropdownAction);

export const ACTION_TYPE = {
  PIPELINE: "PIPELINE",
  DATASET: "DATASET",
  AIAGENT: "AIAGENT",
};
