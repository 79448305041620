export const actionsGroup = [
  {
    key: "Load",
    label: "Load",
    children: [],
  },
  {
    key: "Filter",
    label: "Filter",
    children: [],
  },
  {
    key: "Transform",
    label: "Transform",
    children: [],
  },
  {
    key: "Enrich",
    label: "Enrich",
    children: [],
  },
  {
    key: "Export",
    label: "Export",
    children: [],
  },
  {
    key: "Others",
    label: "Others",
    children: [],
  },
];

export const ACTION_PERMISSION = [
  {
    id: "1",
    name: "READ",
  },
  {
    id: "2",
    name: "WRITE",
  },
  {
    id: "4",
    name: "CREATE",
  },
  {
    id: "8",
    name: "DELETE",
  },
  {
    id: "16",
    name: "ADMINITRATION",
  },
];

export const APPLY_FOR = ["CRAWLER", "DATASET", "AI AGENT", "PIPELINE"];
export const APPLY_AT = ["DATASET", "COLUMN", "RECORD"];
