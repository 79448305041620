import React, { useEffect, useRef, useState } from "react";

const ChatBoxPreview = ({ title, themeSettings }) => {
  const [theme, setTheme] = useState({
    primaryGradientStart: "#6366f1",
    primaryGradientEnd: "#8b5cf6",
    headerTextColor: "#ffffff",
    chatBgColor: "#ffffff",
    messageSentBg: "#ffffff",
    messageSentText: "#1f2937",
    inputBorderColor: "#e5e7eb",
    messagesBg: "#f8fafc",
  });

  useEffect(() => {
    setTheme(themeSettings);
  }, [themeSettings]);

  return (
    <>
      <div
        style={{
          position: "relative",
          background: theme?.chatBgColor,
          borderRadius: "20px",
          boxShadow: "0 8px 24px rgba(0,0,0,0.15)",
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          overflow: "hidden",
          animation: "slideIn 0.3s ease-out",
          display: "block",
        }}
      >
        <div
          style={{
            background: `linear-gradient(135deg, ${theme?.primaryGradientStart} 0%, ${theme?.primaryGradientEnd} 100%)`,
            color: theme?.headerTextColor,
            padding: "20px",
            borderRadius: "20px 20px 0 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          }}
        >
          <span style={{ fontWeight: 600, fontSize: "16px" }}>
            {title ?? "AI Agent"}
          </span>
          <svg width="16px" height="16px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"> <path fill={theme.headerTextColor} d="M600.704 64a32 32 0 0130.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0134.432 15.36L944.32 364.8a32 32 0 01-4.032 37.504l-77.12 85.12a357.12 357.12 0 010 49.024l77.12 85.248a32 32 0 014.032 37.504l-88.704 153.6a32 32 0 01-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 01600.704 960H423.296a32 32 0 01-30.464-22.208L357.696 828.48a351.616 351.616 0 01-42.56-24.64l-112.32 24.256a32 32 0 01-34.432-15.36L79.68 659.2a32 32 0 014.032-37.504l77.12-85.248a357.12 357.12 0 010-48.896l-77.12-85.248A32 32 0 0179.68 364.8l88.704-153.6a32 32 0 0134.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 01423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 00-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 000 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0034.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0034.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 000-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 00-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 110 384 192 192 0 010-384zm0 64a128 128 0 100 256 128 128 0 000-256z"/> </svg>
          <span style={{ cursor: "pointer", fontSize: "20px" }}>×</span>
        </div>

        <div
          style={{
            height: "300px",
            padding: "20px",
            overflowY: "auto",
            background: theme?.messagesBg,
          }}
        >
          <div
            style={{
              margin: "8px 0",
              padding: "12px 18px",
              borderRadius: "18px",
              maxWidth: "75%",
              wordWrap: "break-word",
              lineHeight: 1.4,
              position: "relative",
              animation: "fadeIn 0.3s ease-in-out, slideFromLeft 0.3s ease-out",
              background: `linear-gradient(135deg, ${theme?.primaryGradientStart} 0%, ${theme?.primaryGradientEnd} 100%)`,
              color: theme?.headerTextColor,
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
              float: "left",
              borderBottomLeftRadius: "4px",
            }}
          >
            message example received
          </div>
          <div
            style={{
              margin: "8px 0",
              padding: "12px 18px",
              borderRadius: "18px",
              maxWidth: "75%",
              wordWrap: "break-word",
              lineHeight: 1.4,
              position: "relative",
              animation: "fadeIn 0.3s ease-in-out",

              background: theme.messageSentBg,
              color: theme.messageSentText,
              float: "right",
              borderBottomRightRadius: "4px",
              animation: "slideFromRight 0.3s ease-out",
            }}
          >
            message example sent
          </div>
          <div
            id="typing-indicator"
            style={{
              margin: "8px 0",
              padding: "12px 18px",
              borderRadius: "18px",
              maxWidth: "75%",
              wordWrap: "break-word",
              lineHeight: 1.4,
              position: "relative",
              animation: "fadeIn 0.3s ease-in-out, slideFromLeft 0.3s ease-out",
              background: `linear-gradient(135deg, ${theme?.primaryGradientStart} 0%, ${theme?.primaryGradientEnd} 100%)`,
              color: theme?.headerTextColor,
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
              float: "left",
              borderBottomLeftRadius: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "4px 8px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  background: theme?.headerTextColor,
                  borderRadius: "50%",
                  animation: "typingBounce 1s infinite",
                }}
              ></div>
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  background: theme?.headerTextColor,
                  borderRadius: "50%",
                  animation: "typingBounce 1s infinite",
                  animationDelay: "0.2s",
                }}
              ></div>
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  background: theme?.headerTextColor,
                  borderRadius: "50%",
                  animation: "typingBounce 1s infinite",
                  animationDelay: "0.4s",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "30px",
            width: "100%",
            padding: "15px",
            boxSizing: "border-box",
            display: "flex",
            background: theme?.chatBgColor,
            borderTop: `1px solid ${theme?.inputBorderColor}`,
          }}
        >
          <input
            style={{
              width: "75%",
              padding: "12px 20px",
              border: `2px solid ${theme?.inputBorderColor}`,
              borderRadius: "25px",
              marginRight: "10px",
              fontSize: "14px",
              transition: "all 0.3s ease",
              outline: "none",
            }}
            type="text"
            placeholder="Ask me anything..."
          />
          <button
            style={{
              width: "25%",
              background: `linear-gradient(135deg, ${theme?.primaryGradientStart} 0%, ${theme?.primaryGradientEnd} 100%)`,
              color: theme?.headerTextColor,
              border: "none",
              borderRadius: "25px",
              cursor: "pointer",
              transition: "all 0.2s ease",
              fontSize: "16px",
            }}
          >
            ➤
          </button>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: "8px",
            fontSize: "12px",
            color: "var(--powered-by-text)",
            background: "#fff",
            borderTop: `1px solid ${theme?.inputBorderColor}`,
          }}
        >
          <span>Powered by</span>
          <span style={{position: 'relative'}}>
            <span className="powered-by-logo"> Dalavi</span>
          </span>
        </div>
      </div>
    </>
  );
};
export default ChatBoxPreview;
