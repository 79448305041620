import React from "react";
import { useDispatch } from "react-redux";
import { displayChatbox } from "../../../store/aiContextSlice";
import { Button } from "antd";

const ChatAIContextButton = (props) => {
    const { context } = props;

    const dispatch = useDispatch();

    const openChat = () => {
        dispatch(displayChatbox({ open: true, context: context }));
    }
    return <>
    <Button onClick={openChat} type="primary">Chat</Button>
    </>
}

export default ChatAIContextButton;