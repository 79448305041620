import React from "react";
import "./style.css";
import { Typography } from "antd";
import clsx from "clsx";

const GroupBox = ({ label, children, className }) => {
  return (
    <section className={clsx("group-container", className)}>
      <section className="group-meta markdown">
        <div className="group-title">
          <Typography.Text strong>{label}</Typography.Text>
        </div>
      </section>
      <section className="group-body">{children}</section>
    </section>
  );
};

export default GroupBox;
